<template>
    <div class="storage-container">
        <div class="flex spaceBetween">
            <div class="flex">
                <h3>友虹北京科技有限公司</h3>
                <div class="flex org-storage-box">
                    存储空间：<el-progress  :stroke-width="10" :percentage="50" :format="format" ></el-progress>
                    <el-button type="text"  size='small' >扩容</el-button>
                </div>
            </div>
            <div class="flex right-search-box">
                <span> 用户名称：</span><el-input placeholder="请输入用户名"  size='small' ></el-input>
                <el-button type="primary" size='small'>搜索</el-button>
            </div>
        </div>
        <div>
            <el-table
                size='small'
                :data="tableData"
                style="width: 100%">
                <el-table-column
                    type="index"
                    label="序号"
                    align="center"
                    width="150">
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="用户名称">
                </el-table-column>
                <el-table-column
                    prop="存储空间"
                    label="姓名">
                    <template slot-scope="scope">
                       <el-progress :percentage="50" :stroke-width="10" :format="format"></el-progress>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作">
                    <template slot-scope="scope">
                      <el-button type="text">扩容</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                class="pagebox"
                @current-change="pageChanged"
                :page-size="query.pageSize"
                layout="total,  prev, pager, next, jumper"
                :total="1">
            </el-pagination>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            tableData: [{
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }],
            query:{
                pageNum:1,
                pageSize:10
            }
        }
    },
    methods:{
        pageChanged(){

        },
        format(percentage) {
            return `${percentage} GB:100GB`;
        }
    }
}
</script>
<style lang="less" scoped>
.storage-container{
    .flex{
        display: flex;
        align-items: center;
    }
    .spaceBetween{
        justify-content: space-between;
    }
    .org-storage-box{
        font-size: 16px;
        color: #333;
        margin-left: 100px;
    }
    .right-search-box{
        width: 350px;

        .el-input{
            margin-right: 10px;
        }
        font-size: 14px;
        span{
            display: block;
            width: 34%;
       }
    }
    /deep/ .el-table thead {
        color: #909399;
        font-weight: 500;
        th{
            border: none;
            background: #F2F2F2;
            color: #666;
        }
    }
    /deep/.el-progress{
        display: flex;
        width: 300px;
        margin-right: 50px;
        .el-progress__text{
            font-size: 12px!important;
            color: #999;
            width: 100px;
        }
        
    }
    .pagebox{
        margin-top: 10px;
    }
}

</style>